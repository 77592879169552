const anchors = document.querySelectorAll('a[href*="#"]')

for (let anchor of anchors) {
  anchor.addEventListener('click', function (e) {
    e.preventDefault()
    
    const blockID = anchor.getAttribute('href').substr(1)
    
    document.getElementById(blockID).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  })
}

//form settings
$('body').on('input', '[name="tel"]', function(){
    this.value = this.value.replace(/[^\+\d]/g, '');
    if($(this).val().length > 12){
        $(this).val($(this).val().slice(0, 12));
    }
});

$('body').on('input', '[name="name"]', function(){
    this.value = this.value.replace(/^\s+|[^A-ZА-ЯЁ\s]/gi, '');
    if($(this).val().length > 25){
        $(this).val($(this).val().slice(0, 25));
    }
});
