$(document).ready(() => {
	function utm($this) {
		$this.find('input.js-utm_source:hidden').val(localStorage.utm_source)
		$this.find('input.js-utm_medium:hidden').val(localStorage.utm_medium)
		$this.find('input.js-utm_campaign:hidden').val(localStorage.utm_campaign)
		$this.find('input.js-utm_term:hidden').val(localStorage.utm_term)
		$this.find('input.js-utm_content:hidden').val(localStorage.utm_content)
		$this.find('input.js-utm_referrer:hidden').val(localStorage.utm_referrer)
		$this.find('input.js-_ym_uid:hidden').val(localStorage._ym_uid)
		$this.find('input.js-yclid:hidden').val(localStorage.yclid)
	}

    $('input[name="call"]').click(function () {
        if ($('#call2').is(':checked')) {
            $(".drop .dropdown").removeClass('block');
            let time = $(".dropdown-menu li").parents('.dropdown').find('input').val();
            $('#request input[name=form_title]').val('Перезвонить вам / ' + time + ' / OrbitAi');
        } else {
            $(".drop .dropdown").addClass('block');
            $('#request input[name=form_title]').val('Перезвонить вам / OrbitAi');
        }
    });
    
    /*Dropdown Menu*/
    $('.dropdown').click(function () {
        $(this).attr('tabindex', 1).focus();
        $(this).toggleClass('active');
        $(this).find('.dropdown-menu').slideToggle(300);
    });
    
    $('.dropdown').focusout(function () {
        $(this).removeClass('active');
        $(this).find('.dropdown-menu').slideUp(300);
    });
    
    $('.dropdown .dropdown-menu li').click(function () {
        $(this).parents('.dropdown').find('span').text($(this).text());
        $(this).parents('.dropdown').find('input').attr('value', $(this).attr('id'));
    
        let time = $(".dropdown-menu li").parents('.dropdown').find('input').val();
        $('#request input[name=form_title]').val('Перезвонить вам / ' + time + ' / OrbitAi');
    });
    /*End Dropdown Menu*/

    $('#request').submit(function (e) {
		e.stopPropagation()
		e.preventDefault()

		let valid = true

		let msg = $('#lead-form input[name="msg"]')
		let comment = $('#lead-form input[name="comment"]')
		msg.value = `Заявка с lead-form(modal) | ${comment.val()}`

		$('#request').find('input').each(
			function (value) {
				if (!valid) {
					// break
					return false
				}
				if (value.type === 'submit') {
					// skip
					return true
				}
				let $el = $(value)

				if ($el.hasClass('required') && $el.val().trim() === '') {
					valid = false
					$(this).addClass('error')

					return false
				}
			}
		)

		utm($(this))

		if (valid) {
			$.post(
				'/amo/amo.php', // адрес обработчика
				$('#request').serialize(), // отправляемые данные
			)
				.done(() => {
					$('#request > input[name=tel]').val('')
                    $('#modal-request').modal('toggle')
                    $('#modal-thank').modal('toggle')
                    setTimeout(() => $('#modal-thank').modal('toggle'), 3000);
				})
				.fail((xhr, status, error) => {
					console.log(xhr)
					console.log(status)
					console.log(error)
				})
		}
	})
})
